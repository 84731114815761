<template>
  <ng-load-page
    class="m-top-base song-list-box"
    keyId="playlist_id"
    :request="request"
    :params="params"
    :changeData="changeItem"
    :reload.sync="reload"
    :isMore="isMore"
    :dataList="dataList"
  >
    <template v-slot:default="{data}">
      <a-row :gutter="20">
        <a-col
          v-for="item in data"
          class="m-bottom-md"
          :xs="12"
          :sm="12"
          :md="4"
          :key="item.playlist_id"
        >
          <song-list-item :item="item" isShowCollect @update="updateListItem"></song-list-item>
        </a-col>
      </a-row>
    </template>
  </ng-load-page>
</template>

<script>
  import NgLoadPage from '@/components/NgLoadPage.vue'
  import SongListItem from '@/pages/components/songList/SongListItem.vue'

  export default {
    data() {
      return {
        listParams: {},
        changeItem: [],
        reloadTable: false,
      }
    },

    props: {
      params: Object,
      request: Function,
      reload: Boolean,
      isMore: {
        type: Boolean,
        default: true
      },
      dataList: {
        type: Array,
        default() {
          return []
        }
      }
    },

    components: {
      NgLoadPage,
      SongListItem,
    },
    methods: {
      updateListItem(scope) {
        this.changeItem = [scope];

        this.$nextTick(() => {
          this.changeItem = [];
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .ng-load-page {
      min-height: 240px;
    }
  }
</style>
