<template>
  <div class="music-table-box">
    <ng-table
      :columns="columns"
      :request="request"
      :params="params"
      :reload="reload"
      :rowKey="(scope) => scope.music_id"
      :isMore="isMore"
      :pagination="pagination"
      :changeData="changeTableData"
      :data-list="dataSource"
      :isImmediate="isImmediate"
      @totalChange="onTotalChange"
      :showHeader="false"
      keyId="music_id"
      placement="bottom"
      ref="ngTable"
    >
      <template slot="name" slot-scope="text, scope">
        <div class="opera_img" style="padding-top: 4px">
          <img :src="`${scope.logo_url}`" class="log_url_cell" />
          <img
            src="@/assets/images/web/info_play.svg"
            v-if="!isPlaying(scope.music_id)"
            @click="onClickIcon(scope)"
          />
          <img
            src="@/assets/images/web/pouse.svg"
            v-else
            @click="onPause(scope.music_id)"
          />
        </div>
        <div
          class="ellipsis"
          style="max-width: 260px; padding-left: 12px; padding-top: 4px"
        >
          <a-tooltip placement="top" :title="scope.name">
            <router-link :to="`/musicDetail/${scope.music_id}`">
              <span style="font-weight: 700">{{ scope.name }}</span>
              <span style="color:#ff7b13;border:1px solid;border-radius:5px;margin-left: 6px;font-size: 12px;padding-right: 3px;padding-left: 3px;" v-show='scope.library_type=="1"'>会员</span>
            </router-link>
          </a-tooltip>
          <router-link :to="`/supplierInfo/${scope.suplr_id || '-1'}`">
            <p style="font-weight: 400; font-size: 12px; padding-top: 0px;margin-bottom:0px">
              {{ scope.suplr_name }}
            </p>
          </router-link>
          <a style="font-size: 12px;">ID:{{ scope.music_id }}</a>
        </div>
        <div style="clear: both"></div>
      </template>

      <template slot="labels" slot-scope="text, scope">
        <div style="cursor: pointer">
          <span
            class="label_span"
            v-for="(obj, key) in scope.labels"
            :key="key"
            @click="onClickLables(obj.child[0])"
            :disabled="selecteLabelIds.indexOf(obj.child[0].label_id) != '-1'"
            >{{ obj.child[0].name }}
          </span>
        </div>
      </template>
      <template slot="wave" slot-scope="text, scope">
        <div
          style="height: 20px; width: 320px; display: flex; align-items: center"
        >
          <div style="width: 50px">
            {{ isCurMusic(scope.music_id) ? formatTimeBySecond(curTime) : "" }}
          </div>
          <div style="height: 20px; width: 260px; margin: 0 10px">
            <MusicWaveNew
              :percent="percent(scope.music_id)"
              :list="scope.waveform && scope.waveform.data || DefaultData"
              @move="
                (val) => {
                  onMoveProgress(val, scope.music_id);
                }
              "
            ></MusicWaveNew>
          </div>
        </div>
      </template>

      <template slot="operation" slot-scope="text, scope">
        <music-operation
          :videoInfo="scope"
          @closeDrawer="onCloseDrawer"
          @success="$emit('success')"
          class="operation"
        ></music-operation>
      </template>
    </ng-table>
  </div>
</template>

<script>
import { MusicWaveNew } from "@/components/index";
import NgTable from "@/pages/components/music/NewNgTable.vue";
import MusicOperation from "@/pages/components/music/NewMusicOperation.vue";
import * as $col from "@/config/columns";
import { tooltip as ATooltip } from "ant-design-vue";
import musicHandle from "@/mixins/musicHandle";
import { UpdateMusicList } from "@/mixins/music";
import {formatTimeBySecond} from '@/utils/function'
const DefaultData = (new Array(500)).fill(0.2);

const columns = () => {
  return [
    $col.commonKey("作品/音乐人", "name", { isSlot: true, width: "300px" }),
    $col.commonKey("标签", "labels", { isSlot: true, onlyPc: true }),
    // $col.commonKey('BPM', 'bpm', {width: '80px',}),
    $col.commonKey("波形图", "wave", { isSlot: true }),
    $col.commonKey("时长", "duration", { width: "80px" }),
    $col.commonKey("", "operation", { isSlot: true, width: "180px" }),
  ];
};

export default {
  data() {
    return {
      columns: columns([]),
      labels: [],
      changeTableData: [],
      duration: "",
      curTime: "",
DefaultData,
      //tableList: [],
    };
  },
  mixins: [musicHandle, UpdateMusicList],
  props: {
    isMore: {
      type: Boolean,
      default: true,
    },
    request: Function,
    params: Object,
    dataSource: Array,
    reload: Boolean,
    isImmediate: {
      type: Boolean,
      default: true,
    },
    transformData: Function,
    pagination: {},
    selecteLabelIds: {
      type: Array,
    },
  },

  watch: {
    changeData: {
      handler(newVal) {
        if (newVal && newVal.length > 0) {
          this.changeTableData = newVal;
        }
      },
    },
    "$store.state.globalVideoPlayProgress": {
      handler(val) {
        this.curTime = val.curTime;
      },
    },
  },
  components: {
    NgTable,
    MusicOperation,
    ATooltip,
    MusicWaveNew,
  },
  created() {},
  methods: {
    refresh(paras){
      // console.log("------");
      this.$refs.ngTable.refresh(paras);
    },
    onTotalChange(data) {
      //总数据回传
      this.$emit("changetotalNum", data);
    },
    onClickLables(item) {
      //标签回传
      this.$emit("labelsChange", item);
    },
    onCloseDrawer() {
      this.$refs.ngTable && this.$refs.ngTable.onCloseDrawer();
    },
    isCurMusic(now_id){
       let state = this.$store.state;
      if (!state.globalVideoInfo) return false;
      let { music_id, url } = state.globalVideoInfo;
      return music_id && url && now_id === music_id;
    },
    isPlaying(now_id) {
      let state = this.$store.state;
      if (!state.globalVideoInfo) return false;
      let { music_id, url } = state.globalVideoInfo;
      return state.globalVideoPlaying && music_id && url && now_id === music_id;
    },
    onClickIcon(item) {
      this.isPlaying(item.music_id) ? this.onPause() : this.onPlay(item);
      this.$store.commit("changeVideoList", this.list || []);
    },
    onPlay(item) {
      let musicInfo = item;
      musicInfo.url = musicInfo.audition_url;
      this.$store.commit("changeVideoInfo", item);
      this.$nextTick(() => {
        this.$store.commit("changeVideoPlaying", true);
      });
      this.goPlayMusic(musicInfo);
      this.play_music_id = musicInfo.music_id;
    },
    onPause() {
      this.$store.commit("changeVideoPlaying", false);
    },
    onMoveProgress(item, id) {
      let state = this.$store.state;
      if (!state.globalVideoInfo) return;
      let { music_id } = state.globalVideoInfo;
      if (id != music_id) return;
      this.$store.commit("changeVideoPlayProgressSelf", item);
    },
    percent(id) {
      let state = this.$store.state;
      if (!state.globalVideoInfo) return 0;
      let { music_id } = state.globalVideoInfo;
      if (id != music_id) return 0;
      let globalVideoPlayProgress = state.globalVideoPlayProgress;
      return globalVideoPlayProgress && globalVideoPlayProgress.progress
        ? globalVideoPlayProgress.progress
        : 0;
    },
    formatTimeBySecond,
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  &.music-table-box {
    .ant-table-body td {
      padding: 6px 6px;
      vertical-align: middle;
    }
  }
  a {
    color: #333333;
  }
}
.log_url_cell {
  width: 50px;
  height: 50px;
  border-radius: 4px;
  cursor: pointer;
}
.opera_img {
  float: left;
  img:not(:nth-child(1)) {
    display: none;
    width: 20px;
    position: absolute;
    margin-left: -35px;
    margin-top: 14px;
    cursor: pointer;
  }
  &:hover {
    img:not(:nth-child(1)) {
      display: inline-block;
    }
  }
}
.label_span {
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  margin-right: 9px;
  &:hover {
    color: #ff7b13;
  }
}
.label_span[disabled] {
  color: #ff7b13;
}
::v-deep {
  @media only screen and (max-width: $screen-width-md) {
    .music-info-div {
      font-size: 12px;
    }
    &.music-table-box {
      .ant-table-body tr {
        td:nth-child(2),
        td:nth-child(3),
        td:nth-child(4) {
          display: none;
        }
      }
      .ant-table-body td {
        padding: 12px 12px;
        .music-info-div:nth-child() {
          display: none;
        }
      }
    }
  }
}
</style>
